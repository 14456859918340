import { Directive, ElementRef, Input, OnChanges, SecurityContext, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[safeHTML]',
  standalone: false,
})
export class SafeHtmlDirective implements OnChanges {
  @Input() safeHTML: string | null = null;

  constructor(
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnChanges(changes: SimpleChanges): any {
    if ('safeHTML' in changes) {
      this.elementRef.nativeElement.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, this.safeHTML);
    }
  }
}
